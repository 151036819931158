import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ChevronRightCircle } from 'components/Icons';
import {
  UseHistoryContainerGetRunsQuery,
  DataType,
  RuleStatus,
} from 'codegen/graphql';
import * as Sentry from '@sentry/react';

type Props = {
  run: UseHistoryContainerGetRunsQuery['runs']['edges'][number]['node'];
};

export default function RunCard({ run }: Props): JSX.Element {
  const { completedAt, id, rule, dataType } = run;

  if (rule == null) {
    Sentry.captureMessage(`Rule could not be found for run ${id}`);

    return (
      <div className="border border-neutral-40 bg-neutral-20 rounded mr-6 text-sm font-bold px-[0.9375rem] py-[1.2rem] mt-2 h-[3.5rem]">
        Run could not be found - support has been notified
      </div>
    );
  }

  const { name, id: ruleId, status } = rule;

  const completedAtDate = new Date(parseInt(completedAt));
  const formattedDate = format(completedAtDate, 'do MMM, H:mm');
  const approvalRequired = dataType == DataType.SearchTerms;

  return (
    <Link to={`/history/${ruleId}/run/${id}`}>
      <div
        key={run.id}
        className="border border-neutral-40 rounded bg-white px-[0.9375rem] py-[0.9063rem] flex justify-between text-sm  mt-2 h-[3.5rem] hover:cursor-pointer"
      >
        <div className={`flex justify-between pt-[0.3125rem] `}>
          <div className="w-[10.25rem]">{formattedDate}</div>
          <div className="font-bold">{name}</div>
        </div>
        <div className="flex">
          {approvalRequired && (
            <div className=" border border-primary-100 rounded mr-6 text-sm font-bold px-[0.4375rem] py-[0.1875rem]">
              Keyword Harvesting
            </div>
          )}
          {status === RuleStatus.Archived && (
            <div className="border border-neutral-40 bg-neutral-20 rounded mr-6 text-sm font-bold px-[0.4375rem] py-[0.1875rem]">
              Archived
            </div>
          )}
          <button>
            <ChevronRightCircle />
          </button>
        </div>
      </div>
    </Link>
  );
}
