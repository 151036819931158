import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';

import UnsavedChangesModal from 'components/Modals/UnsavedChangesModal';
import { hasEditedVisualisations } from './helpers';
import useDashboardSelector from 'state/dashboard/useDashboardSelector';
import useDashboardDispatch from 'state/dashboard/useDashboardDispatch';

type Props = {
  saveDashboard: (continueNavigation?: () => void) => Promise<void>;
  dashboardLoading: boolean;
  setUnsavedChangesContinueFunction?: (continueFunction: () => void) => void;
  unsavedChangesContinueFunction?: () => void;
};
export default function UnsavedChanges({
  saveDashboard,
  dashboardLoading,
  unsavedChangesContinueFunction,
  setUnsavedChangesContinueFunction,
}: Props): JSX.Element | null {
  const { unsavedChangesModalOpen } = useDashboardSelector();
  const { updateUnsavedChangesModalOpen } = useDashboardDispatch();

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return (
      hasEditedVisualisations() &&
      currentLocation.pathname !== nextLocation.pathname
    );
  });

  useEffect(
    function initModal() {
      if (blocker.state === 'blocked') {
        if (hasEditedVisualisations()) {
          const continueFunction = (): void => {
            updateUnsavedChangesModalOpen(false);
          };
          setUnsavedChangesContinueFunction?.(() => continueFunction);
          updateUnsavedChangesModalOpen(true);
        } else {
          window.onbeforeunload = () => null;
          blocker.proceed();
        }
      }
    },
    [blocker],
  );

  if (!unsavedChangesModalOpen) {
    return null;
  }

  return (
    <UnsavedChangesModal
      open={unsavedChangesModalOpen}
      setOpen={updateUnsavedChangesModalOpen}
      blocker={blocker}
      save={saveDashboard}
      loading={dashboardLoading}
      title="You're about to leave your dashboard. What would you like to do with the
changes?"
      continueFunction={unsavedChangesContinueFunction}
    />
  );
}
