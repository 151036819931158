import { useState, useContext, useEffect } from 'react';

import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import { ReactComponent as CrossIcon } from 'icons/generic/cross.svg';
import { Action } from 'shared/legacy-types';
import { StepContext } from 'pages/Rule/Steps/StepsContainer';
import Modal from 'components/Modals/Modal';
import useActionModal from 'pages/Rule/Steps/Action/ActionModals/useActionModal';
import ActionTabs from 'pages/Rule/Steps/Action/ActionModals/AddModal/ActionTabs/ActionTabs';
import AddActionContent from 'pages/Rule/Steps/Action/ActionModals/AddModal/AddActionContent';
import { TickCircle } from 'components/Icons';
import {
  CHANGE_STATUS,
  UPDATE_BID,
  UPDATE_BUDGET,
  SEND_EMAIL,
  HARVEST_KEYWORDS,
  SEND_SLACK,
} from 'ui/constants/actions';
import useUserSelector from 'state/user/useUserSelector';
import { getFirstActionDefault } from 'ui/helpers/actions';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddActionModal({ open, setOpen }: Props) {
  const { isInternalUser } = useUserSelector();
  const { step, rule } = useContext(StepContext);
  const firstAction = getFirstActionDefault(
    rule.dataType,
    step.actions,
    isInternalUser,
  );

  const [selectedAction, setSelectedAction] = useState<string>(
    firstAction.type,
  );
  const [selection, setSelection] = useState<Action>(firstAction);

  const {
    updateAction,
    save,
    getActionTab,
    showPauseEnable,
    hasCampaignLink,
    campaignLinksLoading,
  } = useActionModal(
    setSelectedAction,
    setSelection,
    selection,
    selectedAction,
    open,
  );

  const selectionHasValue = () => {
    if (selection.type === UPDATE_BID || selection.type === UPDATE_BUDGET) {
      return !!selection.properties.value;
    }

    if (
      selection.type === CHANGE_STATUS &&
      (isInternalUser || showPauseEnable)
    ) {
      return !!selection.properties.status;
    }

    if (selection.type === SEND_SLACK) {
      return !!selection.properties.channelId;
    }

    return false;
  };

  const isEnabled = () => {
    if (
      selection.type === CHANGE_STATUS &&
      (isInternalUser || showPauseEnable)
    ) {
      return true;
    }
    if (selection.type === SEND_EMAIL) {
      return true;
    }
    if (selection.type === HARVEST_KEYWORDS && hasCampaignLink) {
      return true;
    }

    return selectionHasValue();
  };

  const tabMatchesContent = () => {
    if (selection.type === CHANGE_STATUS) {
      return selectedAction === selection.properties.status;
    }

    return selectedAction === selection.type;
  };

  const actionMatch = tabMatchesContent() && selectionHasValue();

  useEffect(
    function initCurrentAction() {
      setSelection(firstAction);
      if (firstAction.type === CHANGE_STATUS) {
        setSelectedAction('paused');
      } else {
        setSelectedAction(firstAction.type);
      }
    },
    // eslint-disable-next-line
    [open],
  );

  function closeModal() {
    setOpen(false);
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="flex max-h-[46.25rem] min-h-[40rem] w-[57.5rem] bg-white">
        <div className="bg-primary-20 border-neutral-40 scrollbar-hide w-[20.5rem] overflow-auto border border-r ">
          <div className="px-4 pb-8 ">
            <ActionTabs
              setSelectedAction={updateAction}
              selectedAction={selectedAction}
            />
          </div>
        </div>
        <div className="relative mt-3 w-[36.938rem] py-8 pl-6 pr-8 sm:mt-0">
          <div className="flex justify-between">
            <div className="flex">
              {open && (
                <h3 className="mr-1 font-bold">{getActionTab().label}</h3>
              )}
              {actionMatch && (
                <TickCircle
                  background="fill-primary-100"
                  iconColour="stroke-white"
                />
              )}
            </div>
            <div
              className="mt-[0.1875rem] hover:cursor-pointer"
              onClick={() => setOpen(false)}
            >
              <CrossIcon />
            </div>
          </div>
          {open && (
            <AddActionContent
              selectedAction={selectedAction}
              selection={selection}
              setSelection={setSelection}
              getActionTab={getActionTab}
              showPauseEnable={showPauseEnable}
              hasCampaignLink={hasCampaignLink}
              campaignLinksLoading={campaignLinksLoading}
            />
          )}
          <div className="absolute bottom-8 right-8 flex justify-end bg-gray-50 pt-3">
            <SecondaryButton onClick={() => closeModal()}>
              <>Close</>
            </SecondaryButton>
            <div className="ml-4">
              <PrimaryButton
                onClick={() => save(setOpen)}
                disabled={!isEnabled()}
              >
                <>Add action</>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
