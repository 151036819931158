import Visualisation from './Visualisation/Visualisation';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import Spinner from 'ui/Spinner/Spinner';
import * as Sentry from '@sentry/react';
import { Dashboard } from 'codegen/graphql';
import SaveDashboardButton from './SaveDashboardButton';

type Props = {
  startDate: Date | null;
  endDate: Date | null;
  profileId: string;
  currencyCode: string | undefined;
  dashboardLoading: boolean;
  addVisualisation: () => void;
  deleteVisualisation: (visualisationId: string) => void;
  deletingVisualisation: boolean;
  addDashboardLoading: boolean;
  saveDashboard: () => void;
  dashboardError: Error | undefined;
  visualisations: Dashboard['visualisations'];
  updatingVisualisationRunning: boolean;
};

const MAX_VISUALISATIONS = 10;

export default function ReportingDashboard({
  startDate,
  endDate,
  profileId,
  currencyCode,
  dashboardLoading,
  addVisualisation,
  deleteVisualisation,
  deletingVisualisation,
  addDashboardLoading,
  saveDashboard,
  dashboardError,
  visualisations,
  updatingVisualisationRunning,
}: Props): JSX.Element {
  if (dashboardLoading) {
    return (
      <div className="flex justify-center mt-20">
        <LargeSpinner />
      </div>
    );
  }

  if (dashboardError) {
    Sentry.captureMessage(`Error fetching dashboard for profile: ${profileId}`);
    return (
      <div className="text-sm">
        Something went wrong, please try refreshing the page. If the error
        persists, please get in contact with support
      </div>
    );
  }

  function renderVisualisations(): JSX.Element | JSX.Element[] {
    if (!visualisations || visualisations.length === 0) {
      return (
        <div className="text-center my-10 text-neutral-60">
          Add visualisations to start building your dashboard
        </div>
      );
    }

    return visualisations.map((visualisation, index) => {
      return (
        <Visualisation
          key={visualisation.id}
          visualisation={visualisation}
          profileId={profileId}
          currencyCode={currencyCode}
          deleteVisualisation={deleteVisualisation}
          deletingVisualisation={deletingVisualisation}
          startDate={startDate}
          endDate={endDate}
          index={index}
        />
      );
    });
  }

  return (
    <div>
      <div className="mt-4">
        <SaveDashboardButton
          saveDashboard={saveDashboard}
          noVisualisations={visualisations.length === 0}
          loading={updatingVisualisationRunning || dashboardLoading}
        />
        <div>{renderVisualisations()}</div>
        <div className="flex justify-center mb-8">
          <PrimaryButton
            onClick={() => {
              addVisualisation();
            }}
            disabled={
              addDashboardLoading || visualisations.length >= MAX_VISUALISATIONS
            }
            width="w-[10rem]"
          >
            {addDashboardLoading ? (
              <div className="flex justify-center">
                <Spinner colour="stroke-white" />
              </div>
            ) : (
              <>Add Visualisation</>
            )}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
