import { ActionGroup, ActionTab, Action } from 'shared/legacy-types';
import {
  ACTION_GROUPS,
  ACTION_DEFAULTS,
  CHANGE_STATUS,
  HARVEST_KEYWORDS,
  SEND_EMAIL,
  UPDATE_BID,
  UPDATE_BUDGET,
} from '../constants/actions';
import { CAMPAIGNS, SEARCH_TERMS } from '../constants/rules';

export const getNotice = (pauseOrEnable: boolean) => {
  if (pauseOrEnable) {
    return `Will be applied when your conditions are met`;
  }

  return `Receive notifications when this rule is triggered`;
};

export const getActionTab = (
  pauseOrEnable: boolean,
  status: string | undefined,
  stepActionType: string | undefined,
) => {
  let foundAction = {} as ActionTab;
  ACTION_GROUPS.forEach((group: ActionGroup) => {
    const found = group.actions.find((action: any) => {
      if (pauseOrEnable) {
        return action.type === status;
      }

      return action.type === stepActionType;
    });

    if (found) {
      foundAction = found as ActionTab;
    }
  });

  return foundAction;
};

// don't return actions that are not available for the data type or the user
export const getFilteredActions = (
  dataType: string,
  isInternalUser: boolean | undefined,
) => {
  return ACTION_GROUPS.flatMap((group) => group.actions).filter((action) => {
    // for now, only show pause/enable for campaigns (for external users)
    if (
      !isInternalUser &&
      dataType === CAMPAIGNS &&
      (action.type === 'paused' || action.type === 'enabled')
    ) {
      return true;
    }

    return (
      action.availableFor.includes(dataType) &&
      (action.enabled === 'all' ||
        (action.enabled === 'internal' && isInternalUser))
    );
  });
};

export const getFirstActionDefault = (
  dataType: string,
  actions: Action[],
  isInternalUser: boolean | undefined,
) => {
  const filteredActions = getFilteredActions(dataType, isInternalUser);
  if (actions.length > 0) {
    const unusedActions = filteredActions.filter((action) => {
      const actionsInUse = actions.filter((ruleAction) => {
        if (
          (action.type === 'paused' || action.type === 'enabled') &&
          ruleAction.type === CHANGE_STATUS
        ) {
          return true;
        }
        if (action.type === ruleAction.type) {
          return true;
        } else {
          return false;
        }
      });
      if (actionsInUse.length > 0) {
        return false;
      }
      return true;
    });
    if (unusedActions.length === 0) {
      return ACTION_DEFAULTS['updateBid'];
    }
    if (
      unusedActions[0]?.type === 'paused' ||
      unusedActions[0]?.type === 'enabled'
    ) {
      return ACTION_DEFAULTS[CHANGE_STATUS];
    }
    return ACTION_DEFAULTS[
      unusedActions[0]?.type as keyof typeof ACTION_DEFAULTS
    ];
  }
  const firstAction = filteredActions[0];

  if (!firstAction) {
    throw Error('Missing action');
  }

  if (
    firstAction &&
    (firstAction.type === 'paused' || firstAction.type === 'enabled')
  ) {
    return ACTION_DEFAULTS[CHANGE_STATUS];
  }

  return ACTION_DEFAULTS[firstAction.type as keyof typeof ACTION_DEFAULTS];
};

export const getActionsUsed = (
  actions: Action[],
  isInternalUser: boolean | undefined,
  DataType: string,
) => {
  const actionTypesUsed = actions.map((action) => action.type);

  const checkChangeStatus = actionTypesUsed.includes(CHANGE_STATUS);
  const checkCampaignBudgets = actionTypesUsed.includes(UPDATE_BUDGET);
  const checkTargetBids = actionTypesUsed.includes(UPDATE_BID);
  const checkSendEmail = actionTypesUsed.includes(SEND_EMAIL);
  const checkHarvestKeywords = actionTypesUsed.includes(HARVEST_KEYWORDS);

  const usedInternalActions =
    checkSendEmail &&
    checkChangeStatus &&
    (checkCampaignBudgets || checkTargetBids);

  const usedExternalActions =
    checkSendEmail && (checkChangeStatus || checkTargetBids);

  const usedSearchTermsActions =
    DataType === SEARCH_TERMS && checkHarvestKeywords && checkSendEmail;

  if (!isInternalUser && usedExternalActions) {
    return true;
  }
  if (isInternalUser && usedInternalActions) {
    return true;
  }
  if (isInternalUser && usedSearchTermsActions) {
    return true;
  }
  return false;
};
