import { useState } from 'react';
import { TrashIcon } from 'components/Icons';
import Toggle from 'ui/components/Toggles/Toggle';
import { Dashboard, DashboardVisualisationType } from 'codegen/graphql';
import {
  findVisualisationInLocalStorage,
  updateEditedVisualisationInLocalStorage,
} from '../../helpers';
import useDashboardDispatch from 'state/dashboard/useDashboardDispatch';

type Props = {
  visualisation: Dashboard['visualisations'][number];
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  showChart: boolean;
  setShowChart: (show: boolean) => void;
  index: number;
};
export default function VisualisationToolbar({
  visualisation,
  setIsDeleteModalOpen,
  showChart,
  setShowChart,
  index,
}: Props): JSX.Element {
  const [visualisationName, setVisualisationName] = useState<string>(
    visualisation.name,
  );

  const { updateIsVisualisationEdited } = useDashboardDispatch();

  function updateVisualisationName(visualisationName: string): void {
    const editedVisualisation = findVisualisationInLocalStorage(
      visualisation.id,
    );
    const currentVisualisation = editedVisualisation ?? visualisation;
    const copiedVisualisation = { ...currentVisualisation };
    copiedVisualisation.name = visualisationName;
    updateIsVisualisationEdited(true);
    updateEditedVisualisationInLocalStorage(copiedVisualisation);

    setVisualisationName(visualisationName);
  }
  function updateVisualisationType(show: boolean): void {
    const type = show
      ? DashboardVisualisationType.Chart
      : DashboardVisualisationType.Table;

    setShowChart(show);

    const editedVisualisation = findVisualisationInLocalStorage(
      visualisation.id,
    );
    const currentVisualisation = editedVisualisation ?? visualisation;
    const copiedVisualisation = { ...currentVisualisation };
    copiedVisualisation.type = type;
    updateIsVisualisationEdited(true);
    updateEditedVisualisationInLocalStorage(copiedVisualisation);
  }

  return (
    <div className="flex justify-between w-full mb-3 bg-white rounded px-4 py-3 shadow-sm">
      <div className="flex">
        <div
          className="h-[1.5rem]"
          data-testid={`visualisation-name-${visualisationName}`}
        >
          <input
            className="peer:focus:truncate ml-[-0.25rem] h-[1.5rem] w-[21rem] overflow-hidden truncate border-0 bg-white pl-[0.25rem] text-xl focus:pl-[0.25rem] focus:my-[1px] focus:outline-none"
            value={visualisationName}
            onChange={(event) => {
              updateVisualisationName(event.target.value);
            }}
          />
        </div>
      </div>
      <div className="flex">
        <span className="text-neutral-80 mr-2 text-xs flex flex-col justify-end">
          Show chart
        </span>
        <div data-testid={`toggle-chart-${visualisationName}`}>
          <Toggle
            enable={showChart}
            toggle={() => {
              updateVisualisationType(!showChart);
            }}
          />
        </div>
        <div
          className="flex flex-col justify-center ml-5 hover:cursor-pointer"
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
          data-testid={`delete-visualisation-${index}`}
        >
          <TrashIcon />
        </div>
      </div>
    </div>
  );
}
