import { Action, ActionTab, ChangeStatusOption } from 'shared/legacy-types';
import { AsteriskCircle } from 'components/Icons/';
import UpdateValue from 'pages/Rule/Steps/Action/ModalComponents/UpdateValue';
import SendEmail from 'pages/Rule/Steps/Action/ModalComponents/SendEmail';
import PauseEnable from 'pages/Rule/Steps/Action/ModalComponents/PauseEnable';
import HarvestKeywords from 'pages/Rule/Steps/Action/ModalComponents/HarvestKeywords';
import useUserSelector from 'state/user/useUserSelector';
import {
  CHANGE_STATUS,
  HARVEST_KEYWORDS,
  SEND_EMAIL,
  SEND_SLACK,
  UPDATE_BID,
  UPDATE_BUDGET,
} from 'ui/constants/actions';
import Slack from '../../ModalComponents/Slack/Slack';

interface Props {
  selection: Action;
  setSelection: (selection: Action) => void;
  selectedAction: string;
  getActionTab: () => ActionTab;
  showPauseEnable?: boolean;
  hasCampaignLink: boolean;
  campaignLinksLoading: boolean;
}

export default function ActionContent({
  setSelection,
  selection,
  selectedAction,
  getActionTab,
  showPauseEnable,
  hasCampaignLink,
  campaignLinksLoading,
}: Props) {
  const foundAction = getActionTab();
  const { isInternalUser } = useUserSelector();
  const openToAll = foundAction.enabled === 'all';
  const openToInternal = foundAction.enabled === 'internal';

  if (openToAll || (openToInternal && isInternalUser) || showPauseEnable) {
    if (selection.type === UPDATE_BID || selection.type === UPDATE_BUDGET) {
      return (
        <UpdateValue
          selection={selection}
          currentAction={selectedAction}
          setSelection={setSelection}
        />
      );
    }

    if (selection.type === SEND_EMAIL) {
      return <SendEmail />;
    }

    if (selection.type === CHANGE_STATUS) {
      return (
        <PauseEnable
          actionType={foundAction.type as unknown as ChangeStatusOption}
        />
      );
    }
    if (selection.type === HARVEST_KEYWORDS) {
      return (
        <HarvestKeywords
          selection={selection}
          setSelection={setSelection}
          hasCampaignLink={hasCampaignLink}
          campaignLinksLoading={campaignLinksLoading}
        />
      );
    }
  }

  if (selection.type === SEND_SLACK) {
    return <Slack selection={selection} setSelection={setSelection} />;
  }

  if (foundAction.comingSoon) {
    return (
      <div className="border-primary-40 bg-primary-20 mt-6 flex rounded border px-3 py-2 text-neutral-100">
        <span className="mr-[0.375rem] mt-0.5">
          <AsteriskCircle
            background="fill-primary-100"
            iconColour="fill-white"
          />
        </span>
        This feature is coming soon
      </div>
    );
  }

  return null;
}
