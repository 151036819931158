import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type DashboardState = {
  unsavedChangesModalOpen: boolean;
  isVisualisationEdited: boolean;
};

const initialState: DashboardState = {
  unsavedChangesModalOpen: false,
  isVisualisationEdited: false,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateUnsavedChangesModalOpen(state, action: PayloadAction<boolean>) {
      state.unsavedChangesModalOpen = action.payload;
    },
    updateIsVisualisationEdited(state, action: PayloadAction<boolean>) {
      state.isVisualisationEdited = action.payload;
    },
  },
});

export const { updateUnsavedChangesModalOpen, updateIsVisualisationEdited } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
