import GridTable, { TableHeader } from '../../GridTable/GridTable';
import {
  AgGridColumnFilter,
  CAMPAIGNS_TABLE_HEADERS,
  CampaignTableHeader,
} from 'pages/Reporting/GridTable/tableHeaders';
import { Dashboard } from 'codegen/graphql';
import DeleteVisualisationModal from '../DeleteVisualisationModal';
import useVisualisation from './useVisualisation';
import useCampaignMetrics from '../useCampaignMetrics';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import VisualisationToolbar from './VisualisationToolbar';

type Props = {
  visualisation: Dashboard['visualisations'][number];
  currencyCode: string | undefined;
  profileId: string;
  deleteVisualisation: (visualisationId: string) => void;
  deletingVisualisation: boolean;
  startDate: Date | null;
  endDate: Date | null;
  index: number;
};

export default function Visualisation({
  visualisation,
  currencyCode,
  profileId,
  deleteVisualisation,
  deletingVisualisation,
  startDate,
  endDate,
  index,
}: Props): JSX.Element {
  const {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    updateColumnState,
    updateFilterState,
    updateChartState,
    displayVisualisation,
    showChart,
    setShowChart,
  } = useVisualisation(visualisation);

  const { campaigns, campaignsLoading } = useCampaignMetrics(
    startDate,
    endDate,
    profileId,
  );

  const chartId = `chart-id-${visualisation.id}`;
  const gridId = `grid-id-${visualisation.id}`;

  function getChartState() {
    try {
      return (
        displayVisualisation.chartState &&
        JSON.parse(displayVisualisation.chartState)
      );
    } catch (e) {
      console.error('Error parsing chart state');
      return displayVisualisation.chartState;
    }
  }

  const columnState =
    displayVisualisation.columnState &&
    JSON.parse(displayVisualisation.columnState);
  const filterState =
    displayVisualisation.filterState &&
    JSON.parse(displayVisualisation.filterState);
  const chartState = getChartState();

  const tableState = {
    columnState,
    filterState,
    showChart,
    chartState,
  };

  const tableHeaders = [
    {
      field: CampaignTableHeader.NAME,
      headerName: 'Name',
      filter: AgGridColumnFilter.TEXT,
      link: `/reporting/profile/${profileId}/campaigns`,
    },
    ...CAMPAIGNS_TABLE_HEADERS,
  ] as TableHeader[];

  const formattedResults = campaigns.map((campaign) => {
    return {
      ...campaign,
      id: campaign.campaignId,
    };
  });

  async function deleteCurrentVisualisation(): Promise<void> {
    await deleteVisualisation(visualisation.id);
    setIsDeleteModalOpen(false);
  }

  return (
    <div key={visualisation.id}>
      <div className="mb-12">
        <VisualisationToolbar
          visualisation={displayVisualisation}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          showChart={showChart}
          setShowChart={setShowChart}
          index={index}
        />
        <div className={`${showChart && 'hidden'} h-[31rem] mb-6`}>
          {campaignsLoading && (
            <div className="flex justify-center mt-20">
              <LargeSpinner />
            </div>
          )}
          {!campaignsLoading && campaigns.length === 0 && (
            <div className="flex justify-center mt-20">
              <div className="text-neutral-60">
                No results found for this data source
              </div>
            </div>
          )}
          {!campaignsLoading && campaigns.length > 0 && (
            <GridTable
              key={visualisation.id}
              results={formattedResults}
              tableHeaders={tableHeaders}
              showPagination={false}
              currencyCode={currencyCode}
              loading={campaignsLoading}
              showToolbar={true}
              enableCharts={true}
              showGrandTotalRow={true}
              tableState={tableState}
              chartId={chartId}
              gridId={gridId}
              showChart={showChart}
              updateColumnState={updateColumnState}
              updateFilterState={updateFilterState}
              updateChartState={updateChartState}
            />
          )}
        </div>
        {showChart && (
          <div id={chartId} className="ag-theme-quartz h-[41rem]"></div>
        )}
      </div>
      {isDeleteModalOpen && (
        <DeleteVisualisationModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          deletingVisualisation={deletingVisualisation}
          deleteVisualisation={() => deleteCurrentVisualisation()}
        />
      )}
    </div>
  );
}
