import { useAppDispatch } from 'state/hooks';
import {
  updateUnsavedChangesModalOpen,
  updateIsVisualisationEdited,
} from './dashboardSlice';

export default function useDashboardDispatch() {
  const dispatch = useAppDispatch();

  return {
    updateUnsavedChangesModalOpen: (isOpen: boolean) =>
      dispatch(updateUnsavedChangesModalOpen(isOpen)),
    updateIsVisualisationEdited: (isEdited: boolean) =>
      dispatch(updateIsVisualisationEdited(isEdited)),
  };
}
