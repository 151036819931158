import { SymbolType, TableHeader } from 'pages/Reporting/GridTable/GridTable';
import { ChangeColour } from './ChangeCell';
import { IAggFuncParams, ValueGetterParams } from 'ag-grid-enterprise';
import {
  PercentageChangeValue,
  percentageChangeAggregationFunction,
  percentageChangeValueGetter,
} from './ColumnUtils/percentageChange';
import {
  RatioPercentageValue,
  ratioPercentageAggregationFunction,
  ratioPercentageValueGetter,
} from './ColumnUtils/ratioPercentage';
import {
  PercentageChangeForRatioPercentageValue,
  percentageChangeForRatioPercentageAggregationFunction,
  percentageChangeForRatioPercentageValueGetter,
} from './ColumnUtils/percentageChangeForRatioPercentage';
import {
  RatioValue,
  ratioAggregationFunction,
  ratioValueGetter,
} from './ColumnUtils/ratio';
import {
  PercentageChangeForRatioValue,
  percentageChangeForRatioAggregationFunction,
  percentageChangeForRatioValueGetter,
} from './ColumnUtils/percentageChangeForRatio';

export enum CampaignTableHeader {
  NAME = 'campaignName',
  TYPE = 'campaignType',
  STATUS = 'campaignStatus',
  COST = 'cost',
  COST_PREVIOUS = 'costPrevious',
  COST_CHANGE = 'costChange',
  AD_REVENUE = 'adSales',
  AD_REVENUE_PREVIOUS = 'adSalesPrevious',
  AD_REVENUE_CHANGE = 'adSalesChange',
  ACOS = 'acos',
  ACOS_PREVIOUS = 'acosPrevious',
  ACOS_CHANGE = 'acosChange',
  ROAS = 'roas',
  ROAS_PREVIOUS = 'roasPrevious',
  ROAS_CHANGE = 'roasChange',
  IMPRESSIONS = 'impressions',
  IMPRESSIONS_PREVIOUS = 'impressionsPrevious',
  IMPRESSIONS_CHANGE = 'impressionsChange',
  CLICKS = 'clicks',
  CLICKS_PREVIOUS = 'clicksPrevious',
  CLICKS_CHANGE = 'clicksChange',
  CLICK_THROUGH_RATE = 'clickThroughRate',
  CLICK_THROUGH_RATE_PREVIOUS = 'clickThroughRatePrevious',
  CLICK_THROUGH_RATE_CHANGE = 'clickThroughRateChange',
  CPC = 'costPerClick',
  CPC_PREVIOUS = 'costPerClickPrevious',
  CPC_CHANGE = 'costPerClickChange',
  AD_ORDERS = 'adOrders',
  AD_ORDERS_PREVIOUS = 'adOrdersPrevious',
  AD_ORDERS_CHANGE = 'adOrdersChange',
  AD_CONVERSION_RATE = 'adConversionRate',
  AD_CONVERSION_RATE_PREVIOUS = 'adConversionRatePrevious',
  AD_CONVERSION_RATE_CHANGE = 'adConversionRateChange',
}

export enum AgGridColumnFilter {
  NUMBER = 'agNumberColumnFilter',
  TEXT = 'agTextColumnFilter',
  SET = 'agSetColumnFilter',
}

enum AgGridColumnAggregationFunction {
  SUM = 'sum',
}

const TYPE: TableHeader = {
  field: CampaignTableHeader.TYPE,
  headerName: 'Type',
  filter: AgGridColumnFilter.SET,
  enableRowGroup: true,
};

const STATUS: TableHeader = {
  field: CampaignTableHeader.STATUS,
  headerName: 'Status',
  filter: AgGridColumnFilter.SET,
  enableRowGroup: true,
};

const COST: TableHeader = {
  field: CampaignTableHeader.COST,
  headerName: 'Spend',
  symbol: SymbolType.CURRENCY,
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: AgGridColumnAggregationFunction.SUM,
};

const COST_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.COST_PREVIOUS,
  headerName: 'Previous Spend',
  symbol: SymbolType.CURRENCY,
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: AgGridColumnAggregationFunction.SUM,
};

const COST_CHANGE: TableHeader = {
  field: CampaignTableHeader.COST_CHANGE,
  headerName: 'Spend % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.RED,
  negativeChange: ChangeColour.GREEN,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeValueGetter(
      params,
      CampaignTableHeader.COST,
      CampaignTableHeader.COST_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeValue[] = params.values;
    return percentageChangeAggregationFunction(columnValues);
  },
};

const AD_REVENUE: TableHeader = {
  field: CampaignTableHeader.AD_REVENUE,
  headerName: 'Ad Revenue',
  symbol: SymbolType.CURRENCY,
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: AgGridColumnAggregationFunction.SUM,
};

const AD_REVENUE_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.AD_REVENUE_PREVIOUS,
  headerName: 'Previous Ad Revenue',
  symbol: SymbolType.CURRENCY,
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: AgGridColumnAggregationFunction.SUM,
};

const AD_REVENUE_CHANGE: TableHeader = {
  field: CampaignTableHeader.AD_REVENUE_CHANGE,
  headerName: 'Ad Revenue % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeValueGetter(
      params,
      CampaignTableHeader.AD_REVENUE,
      CampaignTableHeader.AD_REVENUE_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeValue[] = params.values;
    return percentageChangeAggregationFunction(columnValues);
  },
};

const ACOS: TableHeader = {
  field: CampaignTableHeader.ACOS,
  headerName: 'ACOS',
  symbol: SymbolType.PERCENTAGE,
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.COST,
      CampaignTableHeader.AD_REVENUE,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const ACOS_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.ACOS_PREVIOUS,
  headerName: 'Previous ACOS',
  filter: AgGridColumnFilter.NUMBER,
  symbol: SymbolType.PERCENTAGE,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.COST_PREVIOUS,
      CampaignTableHeader.AD_REVENUE_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const ACOS_CHANGE: TableHeader = {
  field: CampaignTableHeader.ACOS_CHANGE,
  headerName: 'ACOS % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.RED,
  negativeChange: ChangeColour.GREEN,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeForRatioPercentageValueGetter(
      params,
      CampaignTableHeader.COST,
      CampaignTableHeader.AD_REVENUE,
      CampaignTableHeader.COST_PREVIOUS,
      CampaignTableHeader.AD_REVENUE_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeForRatioPercentageValue[] =
      params.values;
    return percentageChangeForRatioPercentageAggregationFunction(columnValues);
  },
};

const ROAS: TableHeader = {
  field: CampaignTableHeader.ROAS,
  headerName: 'ROAS',
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioValueGetter(
      params,
      CampaignTableHeader.AD_REVENUE,
      CampaignTableHeader.COST,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioValue[] = params.values;
    return ratioAggregationFunction(columnValues);
  },
};

const ROAS_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.ROAS_PREVIOUS,
  headerName: 'Previous ROAS',
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioValueGetter(
      params,
      CampaignTableHeader.AD_REVENUE_PREVIOUS,
      CampaignTableHeader.COST_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioValue[] = params.values;
    return ratioAggregationFunction(columnValues);
  },
};

const ROAS_CHANGE: TableHeader = {
  field: CampaignTableHeader.ROAS_CHANGE,
  headerName: 'ROAS % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeForRatioValueGetter(
      params,
      CampaignTableHeader.AD_REVENUE,
      CampaignTableHeader.COST,
      CampaignTableHeader.AD_REVENUE_PREVIOUS,
      CampaignTableHeader.COST_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeForRatioValue[] = params.values;
    return percentageChangeForRatioAggregationFunction(columnValues);
  },
};

const IMPRESSIONS: TableHeader = {
  field: CampaignTableHeader.IMPRESSIONS,
  headerName: 'Impressions',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const IMPRESSIONS_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.IMPRESSIONS_PREVIOUS,
  headerName: 'Previous Impressions',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const IMPRESSIONS_CHANGE: TableHeader = {
  field: CampaignTableHeader.IMPRESSIONS_CHANGE,
  headerName: 'Impressions % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeValueGetter(
      params,
      CampaignTableHeader.IMPRESSIONS,
      CampaignTableHeader.IMPRESSIONS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeValue[] = params.values;
    return percentageChangeAggregationFunction(columnValues);
  },
};

const CLICKS: TableHeader = {
  field: CampaignTableHeader.CLICKS,
  headerName: 'Clicks',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const CLICKS_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.CLICKS_PREVIOUS,
  headerName: 'Previous Clicks',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const CLICKS_CHANGE: TableHeader = {
  field: CampaignTableHeader.CLICKS_CHANGE,
  headerName: 'Clicks % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeValueGetter(
      params,
      CampaignTableHeader.CLICKS,
      CampaignTableHeader.CLICKS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeValue[] = params.values;
    return percentageChangeAggregationFunction(columnValues);
  },
};

const CLICK_THROUGH_RATE: TableHeader = {
  field: CampaignTableHeader.CLICK_THROUGH_RATE,
  headerName: 'CTR',
  symbol: SymbolType.PERCENTAGE,
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.CLICKS,
      CampaignTableHeader.IMPRESSIONS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const CLICK_THROUGH_RATE_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.CLICK_THROUGH_RATE_PREVIOUS,
  headerName: 'Previous CTR',
  filter: AgGridColumnFilter.NUMBER,
  symbol: SymbolType.PERCENTAGE,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.CLICKS_PREVIOUS,
      CampaignTableHeader.IMPRESSIONS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const CLICK_THROUGH_RATE_CHANGE: TableHeader = {
  field: CampaignTableHeader.CLICK_THROUGH_RATE_CHANGE,
  headerName: 'CTR % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeForRatioPercentageValueGetter(
      params,
      CampaignTableHeader.CLICKS,
      CampaignTableHeader.IMPRESSIONS,
      CampaignTableHeader.CLICKS_PREVIOUS,
      CampaignTableHeader.IMPRESSIONS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeForRatioPercentageValue[] =
      params.values;
    return percentageChangeForRatioPercentageAggregationFunction(columnValues);
  },
};

const CPC: TableHeader = {
  field: CampaignTableHeader.CPC,
  headerName: 'CPC',
  symbol: SymbolType.CURRENCY,
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioValueGetter(
      params,
      CampaignTableHeader.COST,
      CampaignTableHeader.CLICKS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioValue[] = params.values;
    return ratioAggregationFunction(columnValues);
  },
};

const CPC_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.CPC_PREVIOUS,
  headerName: 'Previous CPC',
  filter: AgGridColumnFilter.NUMBER,
  symbol: SymbolType.CURRENCY,
  valueGetter: (params: ValueGetterParams) => {
    return ratioValueGetter(
      params,
      CampaignTableHeader.COST_PREVIOUS,
      CampaignTableHeader.CLICKS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioValue[] = params.values;
    return ratioAggregationFunction(columnValues);
  },
};

const CPC_CHANGE: TableHeader = {
  field: CampaignTableHeader.CPC_CHANGE,
  headerName: 'CPC % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.RED,
  negativeChange: ChangeColour.GREEN,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeForRatioValueGetter(
      params,
      CampaignTableHeader.COST,
      CampaignTableHeader.CLICKS,
      CampaignTableHeader.COST_PREVIOUS,
      CampaignTableHeader.CLICKS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeForRatioValue[] = params.values;
    return percentageChangeForRatioAggregationFunction(columnValues);
  },
};

const AD_ORDERS: TableHeader = {
  field: CampaignTableHeader.AD_ORDERS,
  headerName: 'Ad Orders',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const AD_ORDERS_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.AD_ORDERS_PREVIOUS,
  headerName: 'Previous Ad Orders',
  filter: AgGridColumnFilter.NUMBER,
  aggFunc: 'sum',
};

const AD_ORDERS_CHANGE: TableHeader = {
  field: CampaignTableHeader.AD_ORDERS_CHANGE,
  headerName: 'Ad Orders % Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeValueGetter(
      params,
      CampaignTableHeader.AD_ORDERS,
      CampaignTableHeader.AD_ORDERS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeValue[] = params.values;
    return percentageChangeAggregationFunction(columnValues);
  },
};

const AD_CONVERSION_RATE: TableHeader = {
  field: CampaignTableHeader.AD_CONVERSION_RATE,
  headerName: 'Ad CR%',
  symbol: SymbolType.PERCENTAGE,
  filter: AgGridColumnFilter.NUMBER,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.AD_ORDERS,
      CampaignTableHeader.CLICKS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const AD_CONVERSION_RATE_PREVIOUS: TableHeader = {
  field: CampaignTableHeader.AD_CONVERSION_RATE_PREVIOUS,
  headerName: 'Previous Ad CR%',
  filter: AgGridColumnFilter.NUMBER,
  symbol: SymbolType.PERCENTAGE,
  valueGetter: (params: ValueGetterParams) => {
    return ratioPercentageValueGetter(
      params,
      CampaignTableHeader.AD_ORDERS_PREVIOUS,
      CampaignTableHeader.CLICKS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: RatioPercentageValue[] = params.values;
    return ratioPercentageAggregationFunction(columnValues);
  },
};

const AD_CONVERSION_RATE_CHANGE: TableHeader = {
  field: CampaignTableHeader.AD_CONVERSION_RATE_CHANGE,
  headerName: 'Ad CR% Change',
  filter: AgGridColumnFilter.NUMBER,
  positiveChange: ChangeColour.GREEN,
  negativeChange: ChangeColour.RED,
  isPercentageChangeColumn: true,
  valueGetter: (params: ValueGetterParams) => {
    return percentageChangeForRatioPercentageValueGetter(
      params,
      CampaignTableHeader.AD_ORDERS,
      CampaignTableHeader.CLICKS,
      CampaignTableHeader.AD_ORDERS_PREVIOUS,
      CampaignTableHeader.CLICKS_PREVIOUS,
    );
  },
  aggFunc: (params: IAggFuncParams) => {
    const columnValues: PercentageChangeForRatioPercentageValue[] =
      params.values;
    return percentageChangeForRatioPercentageAggregationFunction(columnValues);
  },
};

export const CORE_TABLE_HEADERS: TableHeader[] = [
  TYPE,
  STATUS,
  COST,
  COST_PREVIOUS,
  COST_CHANGE,
  AD_REVENUE,
  AD_REVENUE_PREVIOUS,
  AD_REVENUE_CHANGE,
  ACOS,
  ACOS_PREVIOUS,
  ACOS_CHANGE,
  ROAS,
  ROAS_PREVIOUS,
  ROAS_CHANGE,
  IMPRESSIONS,
  IMPRESSIONS_PREVIOUS,
  IMPRESSIONS_CHANGE,
  CLICKS,
  CLICKS_PREVIOUS,
  CLICKS_CHANGE,
  CLICK_THROUGH_RATE,
  CLICK_THROUGH_RATE_PREVIOUS,
  CLICK_THROUGH_RATE_CHANGE,
  CPC,
  CPC_PREVIOUS,
  CPC_CHANGE,
];

export const CAMPAIGNS_TABLE_HEADERS: TableHeader[] = [
  ...CORE_TABLE_HEADERS,
  AD_ORDERS,
  AD_ORDERS_PREVIOUS,
  AD_ORDERS_CHANGE,
  AD_CONVERSION_RATE,
  AD_CONVERSION_RATE_PREVIOUS,
  AD_CONVERSION_RATE_CHANGE,
];

export const CAMPAIGN_ADVERTISING_TABLE_HEADERS: TableHeader[] = [
  ...CORE_TABLE_HEADERS,
  AD_ORDERS,
  AD_ORDERS_PREVIOUS,
  AD_ORDERS_CHANGE,
  AD_CONVERSION_RATE,
  AD_CONVERSION_RATE_PREVIOUS,
  AD_CONVERSION_RATE_CHANGE,
];
