import { useEffect, useState } from 'react';
import { CampaignMetrics } from 'shared/legacy-types';
import useReportingRequests from 'api/hooks/requests/useReportingRequests';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';

type useCampaignMetricsHook = {
  campaigns: CampaignMetrics[];
  campaignsLoading: boolean;
};

export default function useCampaignMetrics(
  startDate: Date | null,
  endDate: Date | null,
  profileId: string,
): useCampaignMetricsHook {
  const [campaigns, setCampaigns] = useState<CampaignMetrics[]>([]);
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);

  const formattedStartDate = startDate
    ? format(startDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');
  const formattedEndDate = endDate
    ? format(endDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  const { getCampaignReportingMetrics } = useReportingRequests();

  useEffect(() => {
    async function loadCampaignMetrics(): Promise<void> {
      try {
        setCampaignsLoading(true);
        const response = await getCampaignReportingMetrics(
          profileId,
          formattedStartDate,
          formattedEndDate,
          null,
        );
        setCampaignsLoading(false);
        const campaignData = response.data as CampaignMetrics[];
        setCampaigns(campaignData);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
    profileId &&
      formattedStartDate &&
      formattedEndDate &&
      loadCampaignMetrics();
    // eslint-disable-next-line
  }, [profileId, formattedStartDate, formattedEndDate]);

  return { campaigns, campaignsLoading };
}
