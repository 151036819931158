import { useAppSelector } from 'state/hooks';

export default function useDashboardSelector() {
  return {
    unsavedChangesModalOpen: useAppSelector(
      (state) => state.dashboard.unsavedChangesModalOpen,
    ),
    isVisualisationsEdited: useAppSelector(
      (state) => state.dashboard.isVisualisationEdited,
    ),
  };
}
