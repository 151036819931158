import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import useDashboardSelector from 'state/dashboard/useDashboardSelector';

type Props = {
  saveDashboard: () => void;
  noVisualisations: boolean;
  loading: boolean;
};

export default function SaveDashboardButton({
  saveDashboard,
  noVisualisations,
  loading,
}: Props): JSX.Element | null {
  const { isVisualisationsEdited } = useDashboardSelector();

  const disableSaveButton = loading || !isVisualisationsEdited;

  if (noVisualisations) {
    return null;
  }

  return (
    <div className="flex justify-end mb-10">
      <PrimaryButton
        disabled={disableSaveButton}
        onClick={() => {
          saveDashboard();
        }}
      >
        <>Save Dashboard</>
      </PrimaryButton>
    </div>
  );
}
