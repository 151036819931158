import { SlackAction, UpdateBidAction } from 'shared/legacy-types';
import {
  UPDATE_BID,
  UPDATE_BUDGET,
  SEND_EMAIL,
  HARVEST_KEYWORDS,
  SEND_SLACK,
} from '../../constants/actions';
import { Action } from 'shared/legacy-types';
import useAction from '../../hooks/useAction';

interface Props {
  dataType: string;
  action: Action;
  isLast: boolean;
  userEmail: string;
}

export default function SummaryActions({
  dataType,
  action,
  isLast,
  userEmail,
}: Props) {
  const {
    isPauseOrEnable,
    metricLabel,
    isEmail,
    conditionLabel,
    pauseEnablelabel,
    bidBudgetValue,
    currentActionTab,
    isKeywordHarvesting,
    isSlack,
  } = useAction(action, dataType);

  const actionStyles =
    'border-primary-80 text-primary-100 mr-2 h-[1.5625rem] rounded border px-2 py-[0.1875rem] text-sm font-bold';

  const bidAction = action as UpdateBidAction;
  const slackAction = action as SlackAction;

  function actionSection() {
    const actionBadge = () => {
      if (action?.type === UPDATE_BID) {
        return `${conditionLabel} bid`;
      }
      if (action?.type === UPDATE_BUDGET) {
        return `${conditionLabel} budget`;
      }
      if (action?.type === SEND_EMAIL) {
        return `Send email alert`;
      }
      if (action?.type === SEND_SLACK) {
        return `Send slack message`;
      }
      if (action.type === HARVEST_KEYWORDS) {
        return `Harvest keywords`;
      }

      throw Error(`Missing handler for: ${action.type}`);
    };

    if (isKeywordHarvesting) {
      return <div className={actionStyles}>{actionBadge()}</div>;
    }

    if (isPauseOrEnable) {
      return (
        <div
          className={actionStyles}
        >{`${currentActionTab.label} ${pauseEnablelabel}`}</div>
      );
    }

    if (isEmail) {
      return (
        <>
          <div className={actionStyles}>{actionBadge()}</div>
          <span className="text-neutral-80 mt-[0.14rem] text-sm !leading-[1.25rem]">
            Emails will be sent to{' '}
            <span className="font-bold">{userEmail}</span>
          </span>
        </>
      );
    }

    if (isSlack) {
      return (
        <>
          <div className={actionStyles}>{actionBadge()}</div>
          <span className="text-neutral-80 mt-[0.14rem] text-sm !leading-[1.25rem]">
            Messages will be sent to{' '}
            <span className="font-bold">
              {slackAction.properties.channelName}
            </span>
          </span>
        </>
      );
    }

    return (
      <>
        <div className="border-primary-80 text-primary-100 mr-2 h-[1.5625rem] rounded border px-2 py-[0.1875rem] text-sm font-bold">
          {actionBadge()}
        </div>
        <div className="text-neutral-80 flex text-sm !leading-[1.5625rem]">
          <div className="flex">
            <div>{metricLabel}</div>
            <div className="ml-1.5 mr-[0.313rem]">by</div>
            {bidBudgetValue}%
          </div>
          <span className="mx-[0.313rem]">•</span>
          Wait at least {bidAction.properties.buffer} day
          {bidAction.properties.buffer !== 1 && 's'}
        </div>
      </>
    );
  }

  return (
    <div
      className={`border-primary-100 border-l-2 ${!isLast && 'pb-2'} flex pl-6`}
    >
      {actionSection()}
    </div>
  );
}
