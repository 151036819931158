/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
};

export enum AccountType {
  Agency = 'AGENCY',
  Seller = 'SELLER',
  Vendor = 'VENDOR',
}

export type Brand = {
  __typename?: 'Brand';
  accountType: AccountType;
  id: Scalars['String']['output'];
  marketplaces: Array<Marketplace>;
  marketplacesConnectedCount?: Maybe<Scalars['Int']['output']>;
  marketplacesConnectedForAdsDataCount?: Maybe<Scalars['Int']['output']>;
  marketplacesConnectedForRetailDataCount?: Maybe<Scalars['Int']['output']>;
  marketplacesCount?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  regionCode: RegionCode;
};

export enum CountryCode {
  Ae = 'AE',
  Au = 'AU',
  Be = 'BE',
  Br = 'BR',
  Ca = 'CA',
  De = 'DE',
  Eg = 'EG',
  Es = 'ES',
  Fr = 'FR',
  In = 'IN',
  It = 'IT',
  Jp = 'JP',
  Mx = 'MX',
  Nl = 'NL',
  Pl = 'PL',
  Sa = 'SA',
  Se = 'SE',
  Sg = 'SG',
  Tr = 'TR',
  Uk = 'UK',
  Us = 'US',
  Za = 'ZA',
}

export type Dashboard = {
  __typename?: 'Dashboard';
  id: Scalars['String']['output'];
  marketplaceId: Scalars['String']['output'];
  organisationId: Scalars['String']['output'];
  visualisations: Array<DashboardVisualisation>;
};

export type DashboardVisualisation = {
  __typename?: 'DashboardVisualisation';
  chartState?: Maybe<Scalars['String']['output']>;
  columnState?: Maybe<Scalars['String']['output']>;
  dataSource: DashboardVisualisationDataSource;
  filterState?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  type: DashboardVisualisationType;
};

export enum DashboardVisualisationDataSource {
  CampaignsAggregatedPerformance = 'CAMPAIGNS_AGGREGATED_PERFORMANCE',
  MarketplaceDailyPerformance = 'MARKETPLACE_DAILY_PERFORMANCE',
}

export enum DashboardVisualisationType {
  Chart = 'CHART',
  Table = 'TABLE',
}

export enum DataType {
  Campaigns = 'CAMPAIGNS',
  SearchTerms = 'SEARCH_TERMS',
  Targets = 'TARGETS',
}

export enum HandleSellingPartnerApiOAuthRedirectStatus {
  Error = 'ERROR',
  Success = 'SUCCESS',
}

export type IsDuplicateCampaignLinkCampaignInput = {
  adGroupIds: Array<Scalars['String']['input']>;
  campaignId: Scalars['String']['input'];
};

export type IsDuplicateCampaignLinkInput = {
  destination: IsDuplicateCampaignLinkCampaignInput;
  marketplaceId: Scalars['String']['input'];
  matchTypes: Array<MatchType>;
  source: IsDuplicateCampaignLinkCampaignInput;
  targetingType: TargetingType;
};

export type Marketplace = {
  __typename?: 'Marketplace';
  countryCode: CountryCode;
  id: Scalars['String']['output'];
  isConnectedForAdsData: Scalars['Boolean']['output'];
  isConnectedForRetailData: Scalars['Boolean']['output'];
  sellingPartnerApiOAuthURL?: Maybe<Scalars['String']['output']>;
};

export type MarketplaceDailyPerformance = {
  __typename?: 'MarketplaceDailyPerformance';
  adClicks: Scalars['Int']['output'];
  adImpressions: Scalars['Int']['output'];
  adOrders: Scalars['Int']['output'];
  adRevenue: Scalars['Float']['output'];
  adSpend: Scalars['Float']['output'];
  date: Scalars['String']['output'];
  marketplaceId: Scalars['String']['output'];
};

export enum MatchType {
  Broad = 'BROAD',
  Exact = 'EXACT',
  Phrase = 'PHRASE',
}

export type Mutation = {
  __typename?: 'Mutation';
  addDashboardVisualisation: Dashboard;
  deleteDashboardVisualisation: Dashboard;
  getBrandIdFromSellingPartnerApiOAuthRedirect?: Maybe<
    Scalars['String']['output']
  >;
  handleSellingPartnerApiOAuthRedirect: HandleSellingPartnerApiOAuthRedirectStatus;
  isDuplicateCampaignLink: Scalars['Boolean']['output'];
  updateDashboardVisualisation: Dashboard;
};

export type MutationAddDashboardVisualisationArgs = {
  dataSource: DashboardVisualisationDataSource;
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type MutationDeleteDashboardVisualisationArgs = {
  marketplaceId: Scalars['String']['input'];
  visualisationId: Scalars['String']['input'];
};

export type MutationGetBrandIdFromSellingPartnerApiOAuthRedirectArgs = {
  stateToken: Scalars['String']['input'];
};

export type MutationHandleSellingPartnerApiOAuthRedirectArgs = {
  brandId: Scalars['String']['input'];
  oAuthCode: Scalars['String']['input'];
  stateToken: Scalars['String']['input'];
};

export type MutationIsDuplicateCampaignLinkArgs = {
  input: IsDuplicateCampaignLinkInput;
};

export type MutationUpdateDashboardVisualisationArgs = {
  chartState: Scalars['String']['input'];
  columnState: Scalars['String']['input'];
  filterState: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: DashboardVisualisationType;
  visualisationId: Scalars['String']['input'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  brand?: Maybe<Brand>;
  brands: Array<Brand>;
  dashboard: Dashboard;
  marketplaceDailyPerformance: Array<MarketplaceDailyPerformance>;
  rules: Array<Rule>;
  runs: RunConnection;
};

export type QueryBrandArgs = {
  id: Scalars['String']['input'];
};

export type QueryBrandsArgs = {
  onlyWithMarketplacesConnected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryDashboardArgs = {
  marketplaceId: Scalars['String']['input'];
};

export type QueryMarketplaceDailyPerformanceArgs = {
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
  marketplaceId: Scalars['String']['input'];
};

export type QueryRunsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  first?: Scalars['Int']['input'];
  ruleId?: InputMaybe<Scalars['String']['input']>;
};

export enum RegionCode {
  Eu = 'EU',
  Fe = 'FE',
  Na = 'NA',
}

export type Rule = {
  __typename?: 'Rule';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: RuleStatus;
};

export enum RuleStatus {
  Archived = 'ARCHIVED',
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  Enabled = 'ENABLED',
}

export type Run = {
  __typename?: 'Run';
  completedAt: Scalars['String']['output'];
  dataType: DataType;
  id: Scalars['String']['output'];
  rule?: Maybe<Rule>;
};

export type RunConnection = {
  __typename?: 'RunConnection';
  edges: Array<RunEdge>;
  pageInfo: PageInfo;
};

export type RunEdge = {
  __typename?: 'RunEdge';
  cursor: Scalars['String']['output'];
  node: Run;
};

export enum TargetingType {
  Keyword = 'KEYWORD',
  Product = 'PRODUCT',
}

export type IsDuplicateCampaignLinkMutationVariables = Exact<{
  input: IsDuplicateCampaignLinkInput;
}>;

export type IsDuplicateCampaignLinkMutation = {
  __typename?: 'Mutation';
  isDuplicateCampaignLink: boolean;
};

export type UseHistoryContainerGetBrandsQueryVariables = Exact<{
  onlyWithMarketplacesConnected?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type UseHistoryContainerGetBrandsQuery = {
  __typename?: 'Query';
  brands: Array<{ __typename?: 'Brand'; id: string; name: string }>;
};

export type UseHistoryContainerGetRulesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UseHistoryContainerGetRulesQuery = {
  __typename?: 'Query';
  rules: Array<{ __typename?: 'Rule'; id: string; name: string }>;
};

export type UseHistoryContainerGetRunsQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
  ruleId?: InputMaybe<Scalars['String']['input']>;
  brandId?: InputMaybe<Scalars['String']['input']>;
}>;

export type UseHistoryContainerGetRunsQuery = {
  __typename?: 'Query';
  runs: {
    __typename?: 'RunConnection';
    edges: Array<{
      __typename?: 'RunEdge';
      node: {
        __typename?: 'Run';
        id: string;
        completedAt: string;
        dataType: DataType;
        rule?: {
          __typename?: 'Rule';
          name: string;
          status: RuleStatus;
          id: string;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  };
};

export type UseDashboardGetDashboardQueryVariables = Exact<{
  marketplaceId: Scalars['String']['input'];
}>;

export type UseDashboardGetDashboardQuery = {
  __typename?: 'Query';
  dashboard: {
    __typename?: 'Dashboard';
    id: string;
    marketplaceId: string;
    visualisations: Array<{
      __typename?: 'DashboardVisualisation';
      chartState?: string | null;
      columnState?: string | null;
      dataSource: DashboardVisualisationDataSource;
      filterState?: string | null;
      id: string;
      name: string;
      type: DashboardVisualisationType;
    }>;
  };
};

export type UseDashboardAddDashboardMutationVariables = Exact<{
  marketplaceId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  dataSource: DashboardVisualisationDataSource;
}>;

export type UseDashboardAddDashboardMutation = {
  __typename?: 'Mutation';
  addDashboardVisualisation: {
    __typename?: 'Dashboard';
    id: string;
    marketplaceId: string;
    visualisations: Array<{
      __typename?: 'DashboardVisualisation';
      chartState?: string | null;
      columnState?: string | null;
      dataSource: DashboardVisualisationDataSource;
      filterState?: string | null;
      id: string;
      name: string;
      type: DashboardVisualisationType;
    }>;
  };
};

export type UseVisualisationDeleteVisualisationMutationVariables = Exact<{
  marketplaceId: Scalars['String']['input'];
  visualisationId: Scalars['String']['input'];
}>;

export type UseVisualisationDeleteVisualisationMutation = {
  __typename?: 'Mutation';
  deleteDashboardVisualisation: { __typename?: 'Dashboard'; id: string };
};

export type UseVisualisationUpdateVisualisationMutationVariables = Exact<{
  marketplaceId: Scalars['String']['input'];
  visualisationId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  type: DashboardVisualisationType;
  columnState: Scalars['String']['input'];
  filterState: Scalars['String']['input'];
  chartState: Scalars['String']['input'];
}>;

export type UseVisualisationUpdateVisualisationMutation = {
  __typename?: 'Mutation';
  updateDashboardVisualisation: { __typename?: 'Dashboard'; id: string };
};

export type UseBrandGetBrandQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
}>;

export type UseBrandGetBrandQuery = {
  __typename?: 'Query';
  brand?: {
    __typename?: 'Brand';
    id: string;
    name: string;
    regionCode: RegionCode;
    accountType: AccountType;
    marketplaces: Array<{
      __typename?: 'Marketplace';
      countryCode: CountryCode;
      id: string;
      sellingPartnerApiOAuthURL?: string | null;
      isConnectedForAdsData: boolean;
      isConnectedForRetailData: boolean;
    }>;
  } | null;
};

export type HandleSellingPartnerApiOAuthRedirectMutationVariables = Exact<{
  stateToken: Scalars['String']['input'];
  brandId: Scalars['String']['input'];
  oAuthCode: Scalars['String']['input'];
}>;

export type HandleSellingPartnerApiOAuthRedirectMutation = {
  __typename?: 'Mutation';
  handleSellingPartnerApiOAuthRedirect: HandleSellingPartnerApiOAuthRedirectStatus;
};

export type UseBrandsGetBrandsQueryVariables = Exact<{ [key: string]: never }>;

export type UseBrandsGetBrandsQuery = {
  __typename?: 'Query';
  brands: Array<{
    __typename?: 'Brand';
    id: string;
    marketplacesCount?: number | null;
    marketplacesConnectedForAdsDataCount?: number | null;
    marketplacesConnectedForRetailDataCount?: number | null;
    name: string;
    regionCode: RegionCode;
    accountType: AccountType;
    marketplaces: Array<{
      __typename?: 'Marketplace';
      countryCode: CountryCode;
      id: string;
      isConnectedForAdsData: boolean;
      isConnectedForRetailData: boolean;
    }>;
  }>;
};

export type GetBrandIdFromSellingPartnerApiOAuthRedirectMutationVariables =
  Exact<{
    stateToken: Scalars['String']['input'];
  }>;

export type GetBrandIdFromSellingPartnerApiOAuthRedirectMutation = {
  __typename?: 'Mutation';
  getBrandIdFromSellingPartnerApiOAuthRedirect?: string | null;
};

export const IsDuplicateCampaignLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'IsDuplicateCampaignLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'IsDuplicateCampaignLinkInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isDuplicateCampaignLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  IsDuplicateCampaignLinkMutation,
  IsDuplicateCampaignLinkMutationVariables
>;
export const UseHistoryContainerGetBrandsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseHistoryContainerGetBrands' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyWithMarketplacesConnected' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brands' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyWithMarketplacesConnected' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'onlyWithMarketplacesConnected',
                  },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseHistoryContainerGetBrandsQuery,
  UseHistoryContainerGetBrandsQueryVariables
>;
export const UseHistoryContainerGetRulesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseHistoryContainerGetRules' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rules' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseHistoryContainerGetRulesQuery,
  UseHistoryContainerGetRulesQueryVariables
>;
export const UseHistoryContainerGetRunsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseHistoryContainerGetRuns' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ruleId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'brandId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'runs' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ruleId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ruleId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brandId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'brandId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rule' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dataType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseHistoryContainerGetRunsQuery,
  UseHistoryContainerGetRunsQueryVariables
>;
export const UseDashboardGetDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseDashboardGetDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dashboard' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visualisations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chartState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterState' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDashboardGetDashboardQuery,
  UseDashboardGetDashboardQueryVariables
>;
export const UseDashboardAddDashboardDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UseDashboardAddDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSource' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardVisualisationDataSource' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addDashboardVisualisation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dataSource' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dataSource' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'visualisations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'chartState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'columnState' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filterState' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseDashboardAddDashboardMutation,
  UseDashboardAddDashboardMutationVariables
>;
export const UseVisualisationDeleteVisualisationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UseVisualisationDeleteVisualisation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visualisationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteDashboardVisualisation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visualisationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visualisationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseVisualisationDeleteVisualisationMutation,
  UseVisualisationDeleteVisualisationMutationVariables
>;
export const UseVisualisationUpdateVisualisationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UseVisualisationUpdateVisualisation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'marketplaceId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'visualisationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DashboardVisualisationType' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'columnState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chartState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDashboardVisualisation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketplaceId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'marketplaceId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'visualisationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'visualisationId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'type' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'columnState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'columnState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterState' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chartState' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chartState' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseVisualisationUpdateVisualisationMutation,
  UseVisualisationUpdateVisualisationMutationVariables
>;
export const UseBrandGetBrandDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseBrandGetBrand' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'brandId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'brandId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'sellingPartnerApiOAuthURL',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isConnectedForAdsData' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'isConnectedForRetailData',
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBrandGetBrandQuery,
  UseBrandGetBrandQueryVariables
>;
export const HandleSellingPartnerApiOAuthRedirectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HandleSellingPartnerApiOAuthRedirect' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stateToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'brandId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'oAuthCode' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'handleSellingPartnerApiOAuthRedirect',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stateToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stateToken' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'brandId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'brandId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'oAuthCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'oAuthCode' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  HandleSellingPartnerApiOAuthRedirectMutation,
  HandleSellingPartnerApiOAuthRedirectMutationVariables
>;
export const UseBrandsGetBrandsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UseBrandsGetBrands' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brands' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplaces' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isConnectedForAdsData' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'isConnectedForRetailData',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketplacesCount' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'marketplacesConnectedForAdsDataCount',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'marketplacesConnectedForRetailDataCount',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'regionCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UseBrandsGetBrandsQuery,
  UseBrandsGetBrandsQueryVariables
>;
export const GetBrandIdFromSellingPartnerApiOAuthRedirectDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'GetBrandIdFromSellingPartnerApiOAuthRedirect',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stateToken' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'getBrandIdFromSellingPartnerApiOAuthRedirect',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stateToken' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stateToken' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetBrandIdFromSellingPartnerApiOAuthRedirectMutation,
  GetBrandIdFromSellingPartnerApiOAuthRedirectMutationVariables
>;
